@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";


.listingForm {
  position: relative;
  max-width: 700px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $spacing-7;
  margin: $spacing-7 auto;

  &_box {
    padding: $spacing-4;
    border-radius: $border-radius-base;
    box-shadow: rgba(0, 0, 0, 0.12) 0 6px 16px;
  }

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &_title {
    font-size: $font-size-xxl;
    font-weight: 400;
  }

  &_block {
    display: flex;
    flex-direction: column;
    gap: $spacing-5;
  }

  &_field {
    position: relative;
    padding-bottom: $spacing-6;
  }

  &_fieldGroup {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    gap: $spacing-3;
  }

  &_grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(106px, 1fr));
    gap: $spacing-3;
    text-align: center;
  }

  &_labelArea {
    display: flex;
    align-items: flex-end;
    margin-bottom: $spacing-3;

    .listingForm_label {
      margin-bottom: 0;
    }
  }

  &_label {
    display: flex;
    align-items: center;
    gap: $spacing-2;
    margin-bottom: $spacing-3;
  }

  &_labelExtra {
    font-size: $font-size-s;
    color: $color-grey3;
    margin-left: auto;
  }

  &_error {
    position: absolute;
    bottom: $spacing-1;
    color: $color-red;
    font-size: $font-size-s;
  }

  &_inputWrapper {
    width: 100%;
  }

  &_inputText {
    display: block;
    font-size: $font-size-base;
    padding: 14px $spacing-3;
    background: #fff;
    border: $border-width-base solid $color-gray;
    border-radius: $border-radius-base;
    width: 100%;
    overflow: hidden;

    &[data-focused] {
      border-color: #417054;
      box-shadow: 0 0 6px 2px #417054;
    }
  }

  &_inputTextArea {
    min-height: 130px;
    width: 100%;
    padding: $spacing-4 $spacing-3;
    border-radius: $border-radius-base;
    border: 1px solid $color-gray;
    font-size: $font-size-s;
    font-family: inherit;
    resize: none;

    &[data-focused] {
      border-color: $color-green-main;
      box-shadow: 0 0 6px 2px #417054;
    }

    //&::placeholder {
    //  color: $color-gray-dark;
    //  font-size: $font-size-s;
    //}
  }

  &_bar {
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $spacing-3;
    z-index: $z-index-listings-filters;
    background-color: #fff;
    border: 1px  solid $color-gray;
    padding: $spacing-5;
    border-radius: $border-radius-base;
    box-shadow: rgba(0, 0, 0, 0.12) 0 6px 16px;
  }

  &_iconButton {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $spacing-2;
    line-height: 1px;
  }

  &_actions {
    display: flex;
    gap: $spacing-3;
  }

  &_actionButton {
    min-width: 100px;
  }

  &_deleteButton {
    padding: $spacing-2 14px;
    background-color: unset;
    font-size: $font-size-base;
    border: 1px solid transparent;
    border-radius: $border-radius-base;
    cursor: pointer;

    &[data-hovered] {
      background-color: $color-gray-light;
    }
  }

  &_backButtonText {
    display: none;
  }
}

@include for-screen-from-480 {
  .listingForm {
    padding: 0 $spacing-3;

    &_backButtonText {
      display: block;
    }

    &_box {
      padding: $spacing-6 $spacing-7;
    }
  }
}
