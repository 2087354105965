@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";

.addListingsPage {
  max-width: 800px;
  width: 100%;
  margin: $spacing-7 auto;
  padding: 0 $spacing-3;

  &_title {
    font-weight: 400;
    margin-bottom: $spacing-6;
    font-size: 26px;
    color: $color-black;
  }

  &_listingTypeGrid {
    display: grid;
    grid-template-columns: repeat(2, minmax(120px, 1fr));
    grid-template-rows: repeat(2, 200px);
    gap: $spacing-5;
  }

  &_footer__isFirstListing {
    display: none;
  }
}

@include for-screen-from-568 {
  .addListingsPage {
    &_title {
      font-size: $font-size-xxxl;
    }
  }
}

@include for-screen-from-768 {
  .addListingsPage {
    &_footer__isFirstListing {
      display: block;
    }
  }
}
