@import '@/styles/variables';

.select {
  &_selectedValue {
    padding: $spacing-4 $spacing-3;
    border: $border-width-base solid $color-gray;
    border-radius: $border-radius-base;
    width: 100%;
    overflow: hidden;
    background: #fff;
    line-height: $font-size-xxl;
    font-size: $font-size-base;
    text-align: left;
    min-height: 48px;

    &[data-focused],
    &[data-pressed] {
      outline: unset;
      border-color: #417054;
      box-shadow: 0 0 6px 2px #417054;
    }

    [data-placeholder="true"] {
      color: $color-grey4;
    }
  }

  &_popover {
    padding: $spacing-4;
    background-color: #fff;
    z-index: $z-index-header-dropdown;
    border: $border-width-base solid $color-gray;
    border-radius: $border-radius-base;
    box-shadow: $shadow;
    overflow-y: scroll;
  }

  &_list {
    max-height: 320px;
  }

  &_option {
    background-color: unset;
    transition: background-color $transition-time $transition-variant;
    border-radius: $border-radius-base;
    padding: $spacing-4 $spacing-5;
    color: $color-black;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: $spacing-1;
    }

    &[data-focused] {
      background-color: $color-gray;
      outline: unset;
    }

    &[data-selected] {
      background-color: $color-green-main;
      color: $font-color-light;
      cursor: default;
    }
  }

  @media (forced-colors: active) {
    .my-item.focused {
      background: Highlight;
      color: HighlightText;
    }
  }
}
