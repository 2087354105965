@import '@/styles/variables';

.searchPlace {
  //position: relative;
  width: 100%;

  .search_place_wrapper {
    position: relative;
    transition: top 1s ease-in-out;
  }

  &_select {
    width: 100% !important;
  }

  &_input {
    font-size: $font-size-base;
    line-height: $font-size-xxl;
    padding: $spacing-3 36px;
    background: #fff;
    border: $border-width-base solid $color-gray;
    border-radius: $border-radius-base;
    width: 100%;
    overflow: hidden;

    &:focus {
      box-shadow: $shadow;
      border-bottom-right-radius: unset;
      border-bottom-left-radius: unset;
    }
  }

  &_dropdown {
    min-height: 40px;
    z-index: $z-index-header-dropdown;
    border: $border-width-base solid $color-gray;
    border-bottom-right-radius: $border-radius-base;
    border-bottom-left-radius: $border-radius-base;
    box-shadow: $shadow;
  }

  &_clearIcon,
  &_forwardIcon,
  &_geoIcon {
    position: absolute;
    top: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    width: 40px;
    height: 40px;
    align-items: center;
    font-size: 20px;
    color: $color-gray-dark;
    cursor: pointer;
    line-height: 0;
    border: none;
    background-color: unset;

    svg {
      transition:
        transform 0.2s ease-in-out,
        background-color 0.2s ease-in-out;
    }

    &:hover {
      color: $color-black;

      svg {
        transform: scale(1.15);
      }
    }
  }

  &_geoIcon {
    left: 0;
  }

  &_clearIcon {
    right: 40px;
    border-right: 1px solid $color-gray;

    &__single {
      border-right: unset;
      right: 0;
    }
  }

  &_forwardIcon {
    right: 0;
  }

  &__isMainPage {
    .searchPlace_clearIcon,
    .searchPlace_forwardIcon,
    .searchPlace_geoIcon {
      height: 66px;
      width: 50px;
      font-size: 25px;
    }

    .searchPlace_clearIcon {
      right: 50px;
    }
  }

  &__square {
    .searchPlace_forwardIcon,
    .searchPlace_clearIcon,
    .searchPlace_geoIcon {
      height: 50px;
    }
  }
}



