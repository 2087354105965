@import '@/styles/variables';

.checkboxGroup {
  &_checkbox {
    display: block;
    font-size: $font-size-xs;
    border: $border-width-base solid $color-gray;
    border-radius: $border-radius-base;
    padding: $spacing-4 $spacing-3;
    cursor: pointer;

    &:hover {
      border-color: $color-black;
    }

    &[data-selected]  {
      background-color: $color-black;
      color: $font-color-light;
    }
  }
}
